import React, { useState } from 'react';
import './App.css';


function App() {
  return (
    <div className="App">
      <LinkPreview />
    </div>
  );
}


function LinkPreview() {

  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [links, setLinks] = useState([]);
  const [nftUrl, setnftUrl] = useState('');

  let urlfront = "https://ipfs.io/ipfs/QmQnvfqH4avKH4hR8sk6Ks2dqKn45fFjH8Cf8eTtCpGcya/img%20(";
  let urlback = ").png";
  var url = urlfront.concat(text).concat(urlback);
  const data2 = url;


  async function loadipfs () {
    const res = await fetch(url, {});
    const data = await res;
  } 

  const handleSubmit = async (evt) => {
    setLoading(true);
    evt.preventDefault();
    console.log(`Submitting ${text}`);

    setLoading(false);
    setnftUrl(data2);
  }

  function PreviewCard({ linkData }) {

    return (
      <a className="preview" href={linkData.url}>
        <img src={linkData.image} />
        <div>
          <h4>{linkData.title}</h4>
          <p>{linkData.description}</p>
        </div>
      </a>
    )
  }

  return (
    <div>
      <h1>Potato Checker</h1>
      <a href="https://songbird-explorer.flare.network/address/0xd165e408e54B79122A4c0b03752C948CCD57b6F3/transactions">
        <pre>NFT Contract: 0xd165e408e54B79122A4c0b03752C948CCD57b6F3</pre>
      </a>
      <br></br>
      Go to <a href="https://songbird-explorer.flare.network">Songbird Explorer</a>
      <pre>Search your wallet address</pre>
      <pre>Go to the Tokens tab</pre>
      <pre>Click on Songbird Potatoes</pre>
      <pre>Check the TokenID of the SGBPOTATO you minted</pre>
      <pre>Type it in below and CHECK YOUR POTATO!</pre>

      <form onSubmit={handleSubmit}>
        <input
          type="number"
          min="1"
          max="2000"
          value={text}
          onChange={e => setText(e.target.value)}>

        </input>
        <br />
        <input type="submit" value="Check My Potato" />
      </form>

      <h2>Your Potato!</h2>
      <p>Checking for Token ID of {text}</p>
      <p>If its the first time you search, wait for IPFS to load the image! It might take a few minutes...</p>

      {loading && <h3>Fetching link previews... 🤔🤔🤔</h3>}

      <div>
        <img src={nftUrl} width="300" height="300"></img>
      </div>


      <br></br>
      <br></br>

    </div>
  )
}

export default App;